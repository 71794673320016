<template>
  <main>
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.moduleList')}}-SIGET MOBILE</b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12" lg="4">
            <CSelect
                :label="$t('label.cargoType')"
                :placeholder="$t('label.select')"
                :horizontal="{label:'col-sm-12 col-lg-auto',input:'col-sm-12 col-lg-7'}"
                addLabelClasses="text-right" 
                v-model="cargoTypeSelect"
                :options="computedTpCargoList"
                @change="CargoList($event)"               
            />
          </CCol>
            <!-- Button new module -->
          <CCol sm="12" lg="8" class="d-flex align-items-center justify-content-end">
            <CButton
              color="add"
              v-c-tooltip="{placement:'top-end',content:$t('label.module')}"
              @click="toggleCreate()"
            >
              <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
            </CButton>
          </CCol>
        </CRow>
        <!-- Datatable module -->
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedSigetMobileList"
          :fields="fields"
          :items-per-page="5"
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          sorter
          :loading="tableLoadingLv1"
          >
          <template #loading>
              <loading/>
          </template>
          <template #Order="{item}">
            <td class="center-cell">
              {{ item.Order }}
            </td>
          </template>
          <template #Orden="{item,index}">
            <td class="center-cell">
              {{item.Order}}
                <CButtonGroup>
                  <CButton
                    class="btn btn-light ml-2"
                    square
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.Down'),
                      placement: 'top-end',
                    }"
                    @click="updateItemLv1(item, 0)"
                    :disabled="isSubmit"
                  >
                    <CIcon name="cil-arrow-circle-bottom"/>
                  </CButton>
                  <CButton
                    color="dark"
                    square
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.Up'),
                      placement: 'top-end',
                    }"
                    @click="updateItemLv1(item, 1)"
                    :disabled="isSubmit"
                    >
                    <CIcon name="cil-arrow-circle-top"/>
                  </CButton>
                </CButtonGroup>
              </td>
          </template>
          <template #ModuleName="{item}">
            <td class="center-cell">
              {{ item.ModuleName }}
            </td>
          </template>
          <template #Level="{ item }">
            <td class="center-cell">
              {{ item.Level }}
            </td>
          </template>
          <template #TransaLogin="{ item }">
            <td class="center-cell">
              {{ item.TransaLogin }}
            </td>
          </template>
          <template #FormatedDate="{ item }">
            <td class="center-cell">
              {{ item.FormatedDate }}
            </td>
          </template>
          <template #Status="{ item }">
            <td class="cell-center text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
            </td>
          </template>
          <template #show_details="{item}">
            <td class="center-cell">
              <CButton
                color="edit"
                class="mr-1"
                square
                v-c-tooltip="{
                  content: $t('label.edit')+' '+$t('label.module'),
                  placement: 'top-end',
                }"
                size="sm"
                @click="toggleEdit(item)"
                >
                <CIcon name="pencil" />
              </CButton>
              <CButton
                color="watch"
                square
                v-c-tooltip="{
                  content: $t('label.toView')+' '+$t('label.level'),
                  placement: 'top-end',
                }"
                size="sm"
                @click="toggleLevel1(item)"
                >
                <CIcon name="eye" />
              </CButton>
            </td>
          </template>
          <template #details="{item}">
            <CCollapse
              :show="Boolean(item._toggled)"
              :duration="collapseDuration"
            > 
              <CCardBody>
                <CCol sm="12" lg="12">
                  <!-- Button new sudmodule -->
                  <div  class="d-flex align-items-center justify-content-end">
                    <CButton
                      color="add"
                      v-c-tooltip="{placement:'top-end',content:$t('label.SubModule')}"
                      @click="toggleCreateSub()" 
                    >
                      <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                    </CButton>
                  </div>
                  <!-- Datatable sudmodule -->
                  <dataTableExtended
                    class="align-center-row-datatable table-lv-2"
                    :items="computedSigetMobileSubList"
                    :fields="fields2"
                    :items-per-page="5"
                    sorter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"    
                    :loading="tableLoadingLv1"                                
                    >
                    <template #Order="{item}">
                      <td class="center-cell">
                        {{ item.Order }}
                      </td>
                    </template>
                    <template #Orden="{item,index}">
                      <td class="center-cell">
                        {{item.Order}}&nbsp;&nbsp;
                          <CButtonGroup>
                            <CButton
                              class="btn btn-light ml-2"
                              square
                              size="sm"
                              v-c-tooltip="{
                                content: $t('label.Down'),
                                placement: 'top-end',
                              }"
                              @click="updateItemLv2(item,0)"
                              :disabled="isSubmit"
                            >
                              <CIcon name="cil-arrow-circle-bottom"/>
                            </CButton>
                            <CButton
                              color="dark"
                              square
                              size="sm"
                              @click="updateItemLv2(item,1)"
                              v-c-tooltip="{
                                content: $t('label.Up'),
                                placement: 'top-end',
                              }"
                              :disabled="isSubmit"
                              >
                              <CIcon name="cil-arrow-circle-top"/>
                            </CButton>
                          </CButtonGroup>
                        </td>
                    </template>
                    <template #ModuleName="{item}">
                      <td class="center-cell">
                        {{ item.ModuleName }}
                      </td>
                    </template>
                    <template #Level="{ item }">
                      <td class="center-cell">
                        {{ item.Level }}
                      </td>
                    </template>
                    <template #TransaLogin="{ item }">
                      <td class="center-cell">
                        {{ item.TransaLogin }}
                      </td>
                    </template>
                    <template #FormatedDate="{ item }">
                      <td class="center-cell">
                        {{ item.FormatedDate }}
                      </td>
                    </template>
                    <template #Status="{ item }">
                      <td class="cell-center text-center">
                        <CBadge :color="getBadge(item.Status)">
                          {{ $t('label.'+item.Status) }}
                        </CBadge>
                      </td>
                    </template>
                    <template #show_details="{item}">
                      <td class="centen-cell">
                        <CButton
                          color="edit"
                          class="mr-1"
                          square
                          v-c-tooltip="{
                            content: $t('label.edit')+' '+$t('label.SubModule'),
                            placement: 'top-end',
                          }"
                          size="sm"
                          @click="toggleEditSub(item)"
                          >
                          <CIcon name="pencil" />
                        </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CCardBody>
            </CCollapse>
          </template>
        </dataTableExtended>
          <!-- Modal new module -->
          <modal-siget-mobile
            :modal.sync="modalModuleCreate"
            :GpoCargo="GpoCargo"
            :isEdit="isEdit"
            :title="titleModal"
            :fatherItem="fatherItem"
            @close="closeModal"
            @Update-list="Update"
          />
          <!-- Modal new sudmodule -->
          <modal-sub-module
            :modal.sync="modalSubModuleCreate"
            :GpoCargo="GpoCargo"
            :isEdit="isEdit"
            :title="titleModal"
            :fatherItem="fatherItem"
            :ModuleFather="GpoModuleIdNivel1"
            @close="closeModal"
            @Update-list="Update"
          />

      </CCardBody>
    </CCard>
  </main>
</template>

<script>
import ModalSigetMobile from './modal-siget-mobile';
import ModalSubModule from './modal-sub-module';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { DateFormater } from '@/_helpers/funciones';



//data
function data() {
  return {
    idCollapse:'',
    titleModal: '',
    GpoCargo: {},
    cargoTypeSelect: '',
    modalSubModule:'',
    modalModuleCreate: false,
    modalSubModuleCreate: false,
    collapseDuration: 0,
    group1: [],
    group2: [],
    GpoModuleIdNivel1: {},
    ModuleDeviceItems: {},
    itemsNivelI: [],
    itemsNivelII: [],
    apiStateLoading: false,    
    tableLoadingLv1: false,
    isEdit: false,
    fatherItem: {},
    Order:'',
    ModuleDeviceId:'',
    ModuleDeviceChildId:'',
    Status:0,
    OriginalOrder:'',
    ModifiedOrder:'',
    ModifiedOrder2:'',
    isSubmit:false
  };
}

//methods

function CargoList(event) {
  this.cargoTypeSelect = event.target.value;
  this.getCargoList();
}

function Update() {
  this.modalModuleCreate = false;
  this.modalSubModuleCreate = false;
  this.isEdit = false;
  this.getCargoList();

}

function toggleCreate() {
  this.GpoCargo = this.group1.find(item => item.GpoCargoId === this.cargoTypeSelect)
  this.modalModuleCreate = !this.modalModuleCreate;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.module');
}

function toggleCreateSub() {
  this.GpoCargo = this.group1.find(item => item.GpoCargoId === this.cargoTypeSelect)
  this.modalSubModuleCreate = !this.modalSubModuleCreate;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.SubModule');
}

function toggleEdit(item) {
  this.GpoCargo = this.group1.find(item => item.GpoCargoId === this.cargoTypeSelect)
  this.fatherItem = item;
  this.isEdit = !this.isEdit;
  this.modalModuleCreate = true;
  
  let _lang = this.$i18n.locale;
  let title = _lang=='en' ? this.fatherItem.ModuleNameDsEn : this.fatherItem.ModuleNameDsEs;
  
  this.titleModal =  this.$t('label.edit')+' '+this.$t('label.module')+': '+title;
}

function toggleEditSub(item) {
  this.GpoCargo = this.group1.find(item => item.GpoCargoId === this.cargoTypeSelect)
  this.fatherItem = item;
  this.isEdit = !this.isEdit;
  this.modalSubModuleCreate = true;
  
  let _lang = this.$i18n.locale;
  let title = _lang=='en' ? this.fatherItem.ModuleNameDsEn : this.fatherItem.ModuleNameDsEs;
  
  this.titleModal =  this.$t('label.edit')+' '+this.$t('label.SubModule')+': '+title;
}

function closeModal(){
  this.GpoCargo = {};
  this.fatherItem = {};
  this.modalModuleCreate= false;
  this.modalSubModuleCreate= false;
  this.isEdit= false;
  this.titleModal = '';
}

function closeShow(item, level) {
  let moduleOpen;
  if (level == 1) {
    moduleOpen = this.computedSigetMobileList.find((val) => val._toggled == true);
  } else if (level == 2) {
    moduleOpen = this.computedSigetMobileSubList.find((val) => val._toggled == true);
  }
  if (moduleOpen != undefined && moduleOpen.ModuleDeviceId != item.ModuleDeviceId) {
    moduleOpen._toggled = !moduleOpen._toggled;
  }
}

async function toggleLevel1(item) {
  this.apiStateLoading = true;
  await this.closeShow(item, 1);
  this.GpoModuleIdNivel1 = item;
  const modulePadre = this.itemsNivelI.find(
    (val) => val.ModuleDeviceId === item.ModuleDeviceId
  );
  this.itemsNivelII = modulePadre.ModuleDeviceChildJson;
  item._toggled = !item._toggled;
  if (item._toggled == true) {
    this.idCollapse = item.ModuleDeviceId;
  }else{ 
    this.idCollapse = '';
  };
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
    this.loading = false;
    this.apiStateLoading = false;
  });
}

function updateItemLv1(item,tipo){
  
  this.ModuleDeviceItems = item;

  if(this.ModuleDeviceItems.Status === false || this.ModuleDeviceItems.Status === '' || this.ModuleDeviceItems.Status === null || this.ModuleDeviceItems.Status === undefined|| this.ModuleDeviceItems.Status === 'INACTIVO'){
    return
  }
  if(tipo === 0){
    const itemsActivos = this.computedSigetMobileList.filter(item => item.FgActModuleDevice === true);
    const lengt = itemsActivos.length - 1;
    
    if(parseInt(itemsActivos[lengt].Order) === parseInt(this.ModuleDeviceItems.Order)){
      return
    }
    else{
      let Index = this.computedSigetMobileList.map(function(e) {
        return e.ModuleDeviceId; 
      }).indexOf(this.ModuleDeviceItems.ModuleDeviceId);

      this.ModuleDeviceId = this.ModuleDeviceItems.ModuleDeviceId;
      this.OriginalOrder = this.ModuleDeviceItems.Order;
      this.ModifiedOrder = this.OriginalOrder + 1;
      this.ModifiedOrder2=this.computedSigetMobileList[Index+1].Order-1
      
      if (this.OriginalOrder==this.computedSigetMobileList[Index+1].Order) {
        return
      }
      else{
        try{
          this.apiStateLoading = true;
          this.isSubmit = true;
          let ModuleDeviceJson = {};
                   
          ModuleDeviceJson = [
            {
              ModuleDeviceId:this.ModuleDeviceId,
              Order:this.ModifiedOrder
            },
            {
              ModuleDeviceId:this.computedSigetMobileList[Index+1].ModuleDeviceId,
              Order:this.ModifiedOrder2
            }
          ]
                 
          this.$http.put("ModuleDeviceOrder-update", ModuleDeviceJson, { root: "ModuleDeviceJson" } )
           
          .then((response) => {
            if (response && response.status === (200 || 201)) {
              const messageSuccess = response.data.data[0].Response;
              this.Update();
              this.apiStateLoading = false;
              this.notifySuccess({text: messageSuccess})
              this.isSubmit = false;
            } 
          }).catch((e) => {
            this.isSubmit = false;
            this.apiStateLoading = false;
            this.notifyError({text: e});
          });
        }catch (e) {
          this.isSubmit = false;
          this.apiStateLoading = false;
          this.notifyError({text: e});
        }
      }      
    }  
  }else if(tipo === 1){
    if(this.ModuleDeviceItems.Order === 1){
      return
    }
    let Index = this.computedSigetMobileList.map(function(e) {
      return e.ModuleDeviceId; 
    }).indexOf(this.ModuleDeviceItems.ModuleDeviceId);

    this.ModuleDeviceId = this.ModuleDeviceItems.ModuleDeviceId;
    this.OriginalOrder = this.ModuleDeviceItems.Order; //2
    this.ModifiedOrder = this.OriginalOrder - 1;     //2-1=1
    this.ModifiedOrder2=this.computedSigetMobileList[Index-1].Order+1; 

    if (this.OriginalOrder==this.computedSigetMobileList[Index-1].Order) {
      return
    }else{
      
      try{   
        this.apiStateLoading = true;
        this.isSubmit = true;
        let ModuleDeviceJson = {};
                
        ModuleDeviceJson = [
          {
            ModuleDeviceId:this.ModuleDeviceId,
            Order:this.ModifiedOrder
          },
          {
            ModuleDeviceId:this.computedSigetMobileList[Index-1].ModuleDeviceId,
            Order:this.ModifiedOrder2
          }
        ]
                
        this.$http.put("ModuleDeviceOrder-update", ModuleDeviceJson, { root: "ModuleDeviceJson" } )
          .then((response) => {
            if (response && response.status === (200 || 201)) {
              const messageSuccess = response.data.data[0].Response;
              this.Update();
              this.apiStateLoading = false;
              this.notifySuccess({text: messageSuccess})
              this.isSubmit = false;
            } 
            }).catch((e) => {
              this.isSubmit = false;
              this.apiStateLoading = false;
              this.notifyError({text: e});
            });
      }catch(e){
        this.isSubmit = false;
        this.apiStateLoading = false;
        this.notifyError({text: e});
      }  
    }   
  }
}

function updateItemLv2(item,tipo){
  
  this.ModuleDeviceItems = item;
  
  if(this.ModuleDeviceItems.Status === false || this.ModuleDeviceItems.Status === '' || this.ModuleDeviceItems.Status === null || this.ModuleDeviceItems.Status === undefined|| this.ModuleDeviceItems.Status === 'INACTIVO'){
    return
  }
  if(tipo === 0){
    const itemsActivos = this.computedSigetMobileSubList.filter(item => item.FgActModuleDevice === true);
    const lengt = itemsActivos.length - 1;
    
    if(parseInt(itemsActivos[lengt].Order) === parseInt(this.ModuleDeviceItems.Order)){
      return
    }
    else{
      let Index = this.computedSigetMobileSubList.map(function(e) {
        return e.ModuleDeviceId; 
      }).indexOf(this.ModuleDeviceItems.ModuleDeviceId);

      this.ModuleDeviceId = this.ModuleDeviceItems.ModuleDeviceId;
      this.OriginalOrder = this.ModuleDeviceItems.Order;
      this.ModifiedOrder = this.OriginalOrder + 1;
      this.ModifiedOrder2=this.computedSigetMobileSubList[Index+1].Order-1
  

      if (this.OriginalOrder==this.computedSigetMobileSubList[Index+1].Order) {
        return
      }else{
        
        try{   
          this.apiStateLoading = true;
          this.isSubmit = true;
          let ModuleDeviceJson = {};
                  
          ModuleDeviceJson = [
            {
              ModuleDeviceId:this.ModuleDeviceId,
              Order:this.ModifiedOrder
            },
            {
              ModuleDeviceId:this.computedSigetMobileSubList[Index+1].ModuleDeviceId,
              Order:this.ModifiedOrder2
            }
          ]
                
          this.$http.put("ModuleDeviceOrder-update", ModuleDeviceJson, { root: "ModuleDeviceJson" } )
          
          .then((response) => {
            if (response && response.status === (200 || 201)) {
              const messageSuccess = response.data.data[0].Response;
              this.Update();
              this.apiStateLoading = false;
              this.notifySuccess({text: messageSuccess})
              this.isSubmit = false;
            } 
            }).catch((e) => {
              this.isSubmit = false;
              this.apiStateLoading = false;
              this.notifyError({text: e});
            });
        }catch (e) {
          this.isSubmit = false;
          this.apiStateLoading = false;
          this.notifyError({text: e});
        } 
      }     
    }  
  }else if(tipo === 1){
   
    if(this.ModuleDeviceItems.Order === 1){
      return
    }

    let Index = this.computedSigetMobileSubList.map(function(e) {
      return e.ModuleDeviceId; 
    }).indexOf(this.ModuleDeviceItems.ModuleDeviceId);

    this.ModuleDeviceId = this.ModuleDeviceItems.ModuleDeviceId;
    this.OriginalOrder = this.ModuleDeviceItems.Order;
    this.ModifiedOrder = this.OriginalOrder - 1;
    this.ModifiedOrder2=this.computedSigetMobileSubList[Index-1].Order+1;

    if (this.OriginalOrder==this.computedSigetMobileSubList[Index-1].Order) {
      return
    }else{
        try {   
        this.apiStateLoading = true;
        this.isSubmit = true;
        
        let ModuleDeviceJson = {};
                
        ModuleDeviceJson = 
        
        ModuleDeviceJson = [
          {
            ModuleDeviceId:this.ModuleDeviceId,
            Order:this.ModifiedOrder
          },
          {
            ModuleDeviceId:this.computedSigetMobileSubList[Index-1].ModuleDeviceId,
            Order:this.ModifiedOrder2
          }
        ]
                
        this.$http.put("ModuleDeviceOrder-update", ModuleDeviceJson, { root: "ModuleDeviceJson" } )
          .then((response) => {
            if (response && response.status === (200 || 201)) {
              const messageSuccess = response.data.data[0].Response;
              this.Update();
              this.apiStateLoading = false;
              this.notifySuccess({text: messageSuccess})
              this.isSubmit = false;
            } 
            }).catch((e) => {
              this.isSubmit = false;
              this.apiStateLoading = false;
              this.notifyError({text: e});
            });
      }catch(e){
        this.isSubmit = false;
        this.apiStateLoading = false;
        this.notifyError({text: e});
      }   
    }
    
  }
}


function getCargoList() {
  this.apiStateLoading = true;
  this.$http
    .get('ModuleDeviceGpoCargo-list', {GpoCargoId: this.cargoTypeSelect})
    .then((response) => {
 
      this.itemsNivelI = response.data.data;
      this.apiStateLoading = false;
    })
    .catch((e) => {
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    })  
}

function ListCargoType() {
  this.apiStateLoading = true;
  this.$http
    .get('GpoCargoMobile-list')
    .then(response => {
      let list = response.data.data;
      if (list.length != 0) {
        this.group1 = list;
        this.cargoTypeSelect = this.group1[0].GpoCargoId;
        this.getCargoList();
      }else{
        this.apiStateLoading = false;
      }
    })
    .catch((e) => {
      this.apiStateLoading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    })  
}

// computed 
function computedTpCargoList() {
  return this.group1.map((item) => {
    return {
      label: item.GpoCargoName,
      value: item.GpoCargoId,
    };
  });
}


 function computedSigetMobileList() {
  return this.itemsNivelI.map((item) => {
    if (this.idCollapse==item.ModuleDeviceId) {
      this.itemsNivelII = item.ModuleDeviceChildJson;
    };
    return {
      ...item,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : 'table-dark',
       _toggled: this.idCollapse ? (this.idCollapse==item.ModuleDeviceId ? true : false) : false,
      FormatedDate: item.TranRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TranRegDate)
        : 'N/A',
    };
  });
}


function computedSigetMobileSubList() {
   return this.itemsNivelII.map((item) => {
     return {
       ...item,
       _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        _toggled: false,
       FormatedDate: item.TranRegDate
         ? DateFormater.formatOnlyDateWithSlash(item.TranRegDate)
         : 'N/A',
     };
   });
 }




function fields () {
  return[
    { key: 'Order',label: this.$t('label.order'),_style:'width:3%;text-align:center;' , _classes:"center-cell", },
    { key: 'ModuleNameDsEs',label: this.$t('label.ModuleName')+' '+'(ES)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
    { key: 'ModuleNameDsEn',label: this.$t('label.ModuleName')+' '+'(EN)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
    { key: 'Level',label: this.$t('label.level'),_style:'width:10%;text-align:center;', _classes:"center-cell", },
    { key: 'Orden',label: this.$t('label.order'),_style:'width:10%;text-align:center;', _classes:'center-cell',},
    { key: 'TransaLogin', label: this.$t('label.user'),_style:'width:10%;text-align:center;', _classes:"center-cell", },
    { key: 'FormatedDate',label: this.$t('label.date'),_style:'width:10%;text-align:center;', _classes:"center-cell", },
    { key: 'Status', label: this.$t('label.status'),_style:'width:10%;text-align:center;', _classes:"center-cell", },
    { key: 'show_details',label: '',_style: 'min-width:90px;', sorter: false,}
  ]
}

function fields2 () {
  return[
    { key: 'Order',label: this.$t('label.order'),_style:'width:3%;text-align:center;' , _classes:"center-cell", },
    { key: 'ModuleNameDsEs',label: this.$t('label.SubModuleName')+' '+'(ES)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
    { key: 'ModuleNameDsEn',label: this.$t('label.SubModuleName')+' '+'(EN)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
    { key: 'Level',label: this.$t('label.level'),_style:'width:10%;text-align:center;', _classes:"center-cell", },
    { key: 'Orden',label: this.$t('label.order'),_style:'width:10%;text-align:center;', _classes:'center-cell',},
    { key: 'TransaLogin',label: this.$t('label.user'),_style:'width:10%;text-align:center;', _classes:"center-cell", },
    { key: 'FormatedDate',label: this.$t('label.date'),_style:'width:10%;text-align:center;', _classes:"center-cell", },
    { key: 'Status',label: this.$t('label.status'),_style:'width:10%;text-align:center;', _classes:"center-cell", },
    { key: 'show_details',label: '',_style: 'min-width:45px;', sorter: false,}
  ]
}


export default {
  name: 'module-siget-mobile-index',
  components: {ModalSigetMobile,ModalSubModule},
  data,
  mixins:[GeneralMixin,ModalMixin],
  methods: {
    CargoList,
    Update,
    toggleLevel1,
    toggleCreate,
    toggleCreateSub,
    toggleEdit,
    toggleEditSub,
    closeModal,
    getCargoList,
    ListCargoType,
    updateItemLv1,
    updateItemLv2,
    closeShow
  },
  computed: {
    computedSigetMobileList,
    computedSigetMobileSubList,
    fields,
    fields2,
    computedTpCargoList
  },
  mounted: ListCargoType,
 
}
</script>
<style lang="scss">

.center-cell {
  text-align: center;
  vertical-align: middle;
}

</style>


